import axiosCustom from './customAxios';
import { UserDetailsMarketing } from './models';

const path = 'store-user-details/';

export const storeUserDetails = async (body: UserDetailsMarketing) => {
  const { data, error } = await axiosCustom.post(path, body);
  if (error) {
    console.error(error);
  }
  return data;
};

import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { navigate } from 'gatsby';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import find from 'lodash/find';
import upperFirst from 'lodash/upperFirst';
import axios from 'axios';
import loadable from '@loadable/component';
import Checkbox, { LabelPosition } from '../components/common/Form/Checkbox';

import MainLayout from '../layouts/mainLayout';
import { getAgeName } from '../components/Dashboard/Pets/petUtils';
import {
  Pet,
  DogsAgeType,
  Product,
  PackageSuggestion,
  UserDetailsResponse,
  OrderType,
  Profile,
} from 'models';
import {
  createPetCreationPayload,
  getProductImageUrl,
  deleteAllPetsList,
  updateAllPetsList,
  isBrowser,
  updateCheckoutState,
} from '../utils';

import { createPet, createPet2, getAllPets } from '../services/pets.api';
import { storeUserDetails } from '../services/storeUserDetails';
import { PurchaseType, StatusType } from '../types';
import { isLoggedIn } from '../services/auth.api';
import {
  getSuggestedPlan,
  getSuggestedPlan2,
} from '../services/suggestion.api';
import { getUserProfile } from '../services/userProfile.api';
import {
  addLine,
  createNewBasket,
  fetchProducts,
  removeBasket,
  useAppDispatch,
  useAppSelector,
} from 'state';

const ButtonLoader = loadable(() => import('components/common/ButtonLoader'));
const StatusMessage = loadable(() => import('components/common/StatusMessage'));
const DogsProfile = loadable(() =>
  import('components/PetSuggestion/Step1/DogsProfile'),
);
const LifeStyle = loadable(() =>
  import('components/PetSuggestion/Step2/LifeStyle'),
);
const Breed = loadable(() => import('components/PetSuggestion/Step2/Breed'));
const WeightSlider = loadable(() =>
  import('components/PetSuggestion/Step2/WeightSlider'),
);
const DogsName = loadable(() =>
  import('components/PetSuggestion/Step-1/DogName'),
);
const LifeStage = loadable(() =>
  import('components/PetSuggestion/Step-1/Lifestage'),
);
const Weight = loadable(() => import('components/PetSuggestion/Step-2/Weight'));
const BodyType = loadable(() =>
  import('components/PetSuggestion/Step-2/BodyType'),
);
const Activity = loadable(() =>
  import('components/PetSuggestion/Step-3/Activity'),
);
const FeedPercentage = loadable(() =>
  import('components/PetSuggestion/Step-3/FoodPercent/FeedPercentage'),
);

export default () => {
  const dispatch = useAppDispatch();
  const basket = useAppSelector(state => state.basket.basket);
  const allProducts = useAppSelector(state => state.products.entities);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isBox1Checked, setBox1Checked] = useState(false);
  const [isBox2Checked, setBox2Checked] = useState(false);
  const [sampleChickenProduct, setSampleChickenProduct] = useState({} as Product);
  const [sampleLambProduct, setSampleLambProduct] = useState({} as Product);
  const [petList, setPetList] = useState([]);
  const [status, setStatus] = useState({ message: '', type: StatusType.none });
  const [userProfile, setUserProfile] = useState<Profile>();

  const initialAgeData = {
    type: DogsAgeType.YEARS,
    value: { years: 0, months: 0 },
  };
  const [ageData, setAgeData] = useState(initialAgeData);

  useEffect(() => {
    const sampleChickenProductData = find(
      allProducts,
      item => item.upc === 'TASTE',
    );
    sampleChickenProductData && setSampleChickenProduct(sampleChickenProductData);
    const sampleLambProductData = find(
      allProducts,
      item => item.upc === 'TASTELAMB',
    );
    sampleLambProductData && setSampleLambProduct(sampleLambProductData);
  }, [allProducts]);

  useEffect(() => {
    deleteAllPetsList();
    isBrowser() &&
      window.scrollTo({
        top: 0,
        left: 100,
      });

    (async () => {
      await dispatch(fetchProducts());
      if (isLoggedIn()) {
        setPetList(await getAllPets());
        setUserProfile(await getUserProfile());
      }
      await dispatch(removeBasket());
      await dispatch(createNewBasket());
    })();
  }, []);
  const isValidData = (data = {}) => {
    let newData = {...data}
    delete newData['chicken_taste_tester']
    delete newData['lamb_taste_tester']
    const val = Object.values(newData).every(value => value);
    let ctt = data['chicken_taste_tester']
    let ltt = data['lamb_taste_tester']
    return val && (ctt || ltt);
  };
  const onSubmit = async values => {
    setIsSubmitting(true);
    try {
      // const petCreationPayload: Pet = {
      //   name: values.dogsName,
      //   age: values.lifestage,
      //   weight: values.weight,
      //   activity_level: values.activity,
      //   food_percent: '100',
      //   body_type: values.bodyType,
      // };
      const petCreationPayload = {
        name: values.dogsName,
        age: values.lifestage,
        weight: values.weight,
        activity_level: values.activity,
        food_percent:values.feedPercentage,
        body_type: values.bodyType,
      };

      axios
        .all([
          storeUserDetails({
            email: userProfile ? userProfile.email : values.email?.trim(),
            user_name: userProfile
              ? userProfile.first_name
              : values.userFirstName,
            name: values.dogsName,
            age: values.lifestage,
            activity_level: values.activity,
            dgwght: Number(values.weight),
            food_percent: Number('100'),
            breed: '',
          }),
          getSuggestedPlan2({
            age: values.lifestage,
            dgwght: values.weight,
            body_type: values.bodyType,
            activity_level: values.activity,
          }),
          createPet2(petCreationPayload),
          new Promise(resolve => {
            setTimeout(() => {
              return resolve();
            }, 200);
          }),
        ])
        .then(
          axios.spread(function(
            userDetailsResponse: UserDetailsResponse,
            plan: PackageSuggestion,
            petData: Pet,
          ) {
            const updatedDogsList = [
              {
                petDetails: { ...petCreationPayload, id: petData.id },
                suggestion: {},
                suggestedPlan: {
                  ...plan,
                  original_suggested_frequency: plan.frequency,
                },
              },
            ];
            updateAllPetsList(updatedDogsList);
            (async () => {
              if (values?.chicken_taste_tester){
                await dispatch(
                  addLine({
                    quantity: 1,
                    url: sampleChickenProduct.url,
                    petId: petData.id,
                    frequency: plan.frequency,
                    purchaseType: PurchaseType.ONE_OFF,
                  }),
                );
              }
              if (values?.lamb_taste_tester){
                await dispatch(
                  addLine({
                    quantity: 1,
                    url: sampleLambProduct.url,
                    petId: petData.id,
                    frequency: plan.frequency,
                    purchaseType: PurchaseType.ONE_OFF,
                  }),
                );
              }
              setIsSubmitting(false);
              const checkoutState = {
                allProducts,
                boxCount: 1,
                frequency: plan.frequency,
                dogsName: values.dogsName,
                userFirstName: userProfile
                  ? userProfile.first_name
                  : values.userFirstName,
                email: userProfile ? userProfile.email : values.email?.trim(),
                isTester: true,
                total: basket?.total_incl_tax,
                basketUrl: basket?.url,
                totalExcludingDiscounts: basket?.total_incl_tax_excl_discounts,
                isExistingUser: userDetailsResponse.is_existing_user,
              };
              updateCheckoutState(checkoutState);
              navigate(`/checkout?ot=${OrderType.OneOff}`, {
                state: checkoutState,
              });
            })();
          }),
        );
    } catch (ex) {
      setIsSubmitting(false);
      let message = '';

      if (
        ex.length > 0 &&
        ex[0] === 'You have already added a pet with this name.'
      ) {
        message =
          "Sorry pet parent, it looks like you already have a furbaby with that name. Please try again with another dog's details. If you need any assistance please call us on 09 86 9738 and we will be very happy to help you process your order over the phone.";
      } else {
        message =
          "Sorry pet parents, we seem to be experiencing some technical difficulties today but we're working hard to fix them. Please call us on 09 886 9738 and we will be very happy to help you or process your order over the phone";
      }

      setStatus({
        message: message,
        type: StatusType.error,
      });
    }
  };

  const validationObj = {
    activity: Yup.string().required(),
    bodyType: Yup.string().required(),
    dogsName: Yup.string()
      .max(20)
      .required()
      .matches(/^[-&' 0-9a-zA-Z]+$/, 'Invalid characters')
      .label("Dog's name"),
    weight: Yup.string()
      .required()
      .label('Weight'),
    lifestage: Yup.string().required(),
  };

  if (!isLoggedIn()) {
    validationObj.userFirstName = Yup.string()
      .required()
      .matches(/^[-&' 0-9a-zA-Z]+$/, 'Invalid characters')
      .label('First name');
    validationObj.email = Yup.string()
      .email()
      .trim()
      .required()
      .label('Email');
  }

  const validationSchema = Yup.object(validationObj);

  return (
    <MainLayout withTitle>
      <div className="page-wrapper">
        <div className="add-edit-det-wrapper">
          <div className="def-w-max">
            <div className="det-content-wrapper">
              <div className="hdr text-center">
                <h2 className="mgn-bot-20">
                  {sampleChickenProduct.price
                    ? '$' + parseFloat(sampleChickenProduct.price?.incl_tax)
                    : ''}{' '}
                  TASTE TESTER
                </h2>
                <p>
                  Want to try a sample before you buy a box? No worries!
                  <br />
                  Just fill in the details below to order a generous 150g taste
                  tester.
                  <br />
                  Price includes overnight courier delivery anywhere in New
                  Zealand (RD extra).
                </p>
              </div>
              <Formik
                initialValues={{
                  activity: '',
                  bodyType: '',
                  dogsName: '',
                  lifestage: '',
                  weight: '',
                  ...(!isLoggedIn() ? { userFirstName: '', email: '' } : {}),
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  touched,
                  errors,
                  dirty,
                  isValid,
                }) => (
                  <Form noValidate={true} onSubmit={handleSubmit}>
                    <div className="su-contents">
                      <div className="main-cn">
                        <div className="su-sections">
                          {/* <div className="su-item-margin">
                            <DogsProfile
                              values={values}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}
                              ageData={ageData}
                              setAgeData={setAgeData}
                              isMultipleDog={true}
                            />
                          </div>
                          <div className="su-item-margin">
                            <div className="wt-lt-wrapper mgn-top-20">
                              <div className="wt-lt-item mgn-bot-40">
                                <div className="sub-hdr mgn-bot-10">Weight</div>
                                <WeightSlider name="weight" />
                              </div>
                            </div>
                          </div>
                          <div className="su-item-margin">
                            <div className="wt-lt-wrapper mgn-top-100">
                              <div className="wt-lt-item mgn-bot-40">
                                <div className="sub-hdr">Lifestyle</div>
                                <div className="lifestyle-slider-wrapper">
                                  <LifeStyle name="lifeStyle" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="su-item-margin">
                            <div className="wt-lt-wrapper mgn-top-100">
                              <div className="wt-lt-item mgn-bot-40">
                                <div className="sub-hdr">Breed</div>
                                <Breed name="breed" />
                              </div>
                            </div>
                          </div>
                          */}

                          <div className="su-item-margin spacer">
                            <LifeStage
                              values={values}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                          <div className="su-item-margin spacer">
                            <BodyType
                              values={values}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                          <div className="su-item-margin spacer">
                            <Activity
                              values={values}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                          <div className="su-item-margin spacer">
                            <DogsName
                              values={values}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                          <div className="su-item-margin spacer">
                            <Weight
                              values={values}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                          <div className="align-col-cen spacer">
                            <FeedPercentage
                                values={values}
                                handleChange={handleChange}
                                errors={errors}
                                touched={touched}
                            />
                          </div>

                          <div className="su-item-margin">
                            <div className="wt-lt-wrapper mgn-top-100">
                              {!isLoggedIn() && (
                                <div className="dog-item">
                                  <div className="row">
                                    <div
                                      className="col-sm-6 mgn-bot-30"
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                      }}
                                    >
                                      <Form.Group controlId="formFirstName">
                                        <Form.Label>Your First Name</Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="userFirstName"
                                          placeholder=""
                                          value={values.userFirstName}
                                          onChange={handleChange}
                                          isInvalid={
                                            touched.userFirstName &&
                                            !!errors.userFirstName
                                          }
                                          className={
                                            isLoggedIn() ? '' : 'mgn-bot-20'
                                          }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.userFirstName}
                                        </Form.Control.Feedback>

                                        <span
                                          className="txt-underline cur-pointer primary-text"
                                          onClick={() => navigate('/login')}
                                        >
                                          Already have an account? Log in here
                                        </span>
                                      </Form.Group>
                                    </div>
                                    <div
                                      className="col-sm-6 mgn-bot-30"
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                      }}
                                    >
                                      <Form.Group controlId="formEmail">
                                        <Form.Label>
                                          Your Email Address
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="email"
                                          placeholder=""
                                          value={values.email}
                                          onChange={handleChange}
                                          isInvalid={
                                            touched.email && !!errors.email
                                          }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.email}
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="su-item-margin">
                            <div className="sub-hdr mgn-bot-20">
                              Order Details
                            </div>
                            <div className="row">
                              <div className="col-md-2">
                                <div className="img-wrap">
                                  <img
                                    alt={sampleChickenProduct.title}
                                    src={getProductImageUrl(
                                      sampleChickenProduct.image,
                                    )}
                                    style={{ maxWidth: '100%' }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 check-the-meatbox">
                                <div>
                                  {/* <p className="mgn-bot-10">
                                    {upperFirst(sampleProduct.title || '')}
                                  </p> */}
                                  <div className="d-block mb-15 checkbox-wrap">
                                    <Form.Control
                                      as={Checkbox}
                                      name="chicken_taste_tester"
                                      id="box1"
                                      label={sampleChickenProduct.title}
                                      labelPos={LabelPosition.post}
                                      checked={isBox1Checked}
                                      onChange={() => setBox1Checked(!isBox1Checked)}
                                      value={'chicken'}
                                    />
                                  </div>
                                  <div className="d-block mgn-bot-10 checkbox-wrap font-">
                                  {sampleLambProduct?.title ? <Form.Control
                                      as={Checkbox}
                                      name="lamb_taste_tester"
                                      id="box2"
                                      label={sampleLambProduct.title}
                                      labelPos={LabelPosition.post}
                                      checked={isBox2Checked}
                                      onChange={() => setBox2Checked(!isBox2Checked)}
                                      value={'lamb'}
                                    />: ''}
                                  </div>
                                  {/* <p>150gm Bag</p> */}
                                </div>
                                {/* <div className="row no-mgn">
                                  <p>Quantity: </p>
                                  <div className="row qnty-btns-wrapper">1</div>
                                </div> */}
                              </div>
                            </div>
                          </div>

                          <div className="su-btn text-center mgn-top-50">
                            <ButtonLoader
                              className="btn-def btn-primary"
                              type="submit"
                              text="BUY SAMPLE"
                              loading={isSubmitting}
                              disabled={!isValidData(values)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
              <StatusMessage message={status.message} type={status.type} />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
